import React from "react";
import {
  Button,
  Icon,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { IoIosArrowForward } from "react-icons/io";
import { CiViewTable } from "react-icons/ci";

const NavBar = ({ isFullNavBar, setIsFullNavBar }) => {
  const bg = useColorModeValue("gray.100", "gray.900");
  const bgButton = useColorModeValue("gray.200", "gray.700");
  const bgButtonActive = useColorModeValue("blackAlpha.300", "gray.600");
  const location = useLocation();
  const { pathname } = location;

  return (
    <VStack
      zIndex={110}
      position={"relative"}
      bg={bg}
      minH={"100vh"}
      h={"100%"}
      w={"100%"}
      maxW={isFullNavBar ? "260px" : "60px"}
      transition={"all 0.6s ease"}
    >
      <VStack w={"100%"} spacing={"10px"}>
        <Button
          overflow={"hidden"}
          alignSelf={"end"}
          mr={isFullNavBar ? "13px" : "6px"}
          mb={"10px"}
          mt={"20px"}
          onClick={() => setIsFullNavBar((prev) => !prev)}
          size={"sm"}
          variant="ghost"
        >
          <Icon
            w={"25px"}
            h={"25px"}
            transform={isFullNavBar && "scale(-1, 1)"}
            transition={"all 0.6s ease"}
            as={IoIosArrowForward}
          />
        </Button>
        <Tooltip label={"All Sources"}>
          <Button
            overflow={"hidden"}
            leftIcon={<CiViewTable style={{ height: "20px", width: "20px" }} />}
            h={"50px"}
            w={"90%"}
            bg={pathname === "/all-sources" ? bgButtonActive : bgButton}
            style={
              pathname === "/all-sources"
                ? { fontWeight: "500" }
                : { fontWeight: "400" }
            }
            as={NavLink}
            to={`/all-sources`}
            justifyContent={"start"}
          >
            {isFullNavBar && "All Sources"}
          </Button>
        </Tooltip>
        <Tooltip label={"Tasks"}>
          <Button
            overflow={"hidden"}
            leftIcon={<CiViewTable style={{ height: "20px", width: "20px" }} />}
            h={"50px"}
            w={"90%"}
            bg={pathname === "/tasks" ? bgButtonActive : bgButton}
            style={
              pathname === "/tasks"
                ? { fontWeight: "500" }
                : { fontWeight: "400" }
            }
            as={NavLink}
            to={`/tasks`}
            justifyContent={"start"}
          >
            {isFullNavBar && "Tasks"}
          </Button>
        </Tooltip>
      </VStack>
    </VStack>
  );
};

export default observer(NavBar);
