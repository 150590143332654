import { makeAutoObservable } from "mobx";

class AppStore {
  userName = null;

  constructor() {
    makeAutoObservable(this);
  }

  setUserName(value) {
    this.userName = value;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AppStore();
