import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input, InputGroup,
    InputRightElement,
    useColorModeValue
} from "@chakra-ui/react";
import {Field, useField} from "formik";
import React from "react";

const TextField = ({label, ...props}) => {
    const bgInput = useColorModeValue("gray.200", "gray.800");
    const [show, setShow] = React.useState(false);
    const bgButton = useColorModeValue("gray.200", "gray.700");
    const handleClick = () => setShow(!show);
    const [field, meta] = useField(props);
    return (
        <FormControl isInvalid={meta.error && meta.touched}>
        <FormLabel>{label}</FormLabel>
        <InputGroup size="md">
        <Field
            as={Input}
            bg={bgInput}
            {...field}
            type={show ? "text" : "password"}
            {...props}
        />
            {field.name === "password" && (
            <InputRightElement width="4.5rem">
                <Button
                    bg={bgButton}
                    h="1.75rem"
                    mr="8px"
                    size="xs"
                    onClick={handleClick}
                >
                    {show ? "Hide" : "Show"}
                </Button>
            </InputRightElement>
        )}
        </InputGroup>
        <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>)
}

export default TextField;

