import React from "react";
import { ChakraProvider, HStack, theme } from "@chakra-ui/react";
import { Route, Routes, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Login from "./pages/Login";
import TOKEN from "./utils";
import NavBar from "./components/NavBar";
import UserBar from "./components/UserBar";
import AllSources from "./pages/AllSources";
import Tasks from "./pages/Tasks";

function App() {
  const [isAuth, setIsAuth] = React.useState(!!localStorage.getItem(TOKEN));
  const [isFullNavBar, setIsFullNavBar] = React.useState(true);

  return (
    <ChakraProvider theme={theme}>
      {isAuth && <UserBar setIsAuth={setIsAuth} />}
      <HStack
        position={"relative"}
        minH={"100vh"}
        w={"100%"}
        justifyContent={"stretch"}
        alignItems={"stretch"}
      >
        {isAuth && (
          <NavBar
            setIsFullNavBar={setIsFullNavBar}
            isFullNavBar={isFullNavBar}
          />
        )}
        <Routes>
          <Route
            path="/login"
            element={
              isAuth ? (
                <Navigate to="/" />
              ) : (
                <Login setIsAuth={setIsAuth} isRegistration={false} />
              )
            }
          />
          <Route
            path={"/"}
            element={isAuth ? <AllSources /> : <Navigate to="/login" />}
          />
          <Route
            path={"/all-sources"}
            element={isAuth ? <AllSources /> : <Navigate to="/login" />}
          />
          <Route
            path={"/tasks"}
            element={isAuth ? <Tasks /> : <Navigate to="/login" />}
          />
        </Routes>
      </HStack>
    </ChakraProvider>
  );
}

export default observer(App);
