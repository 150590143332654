import React, { useState, useEffect } from "react";
import {
  Flex,
  useColorModeValue,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  useToast,
  Popover,
  PopoverTrigger,
  Text,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Spinner,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import API from "../api";

const Tasks = () => {
  const toast = useToast({
    position: "bottom-right",
    duration: 3000,
    isClosable: true,
  });
  const bg = useColorModeValue("gray.200", "gray.900");
  const [tasks, setTasks] = useState([]);
  const [isLoaded, setItLoaded] = useState(false);

  const handleGetAllSources = () =>
    API.tasks
      .getAll()
      .then((res) => {
        setTasks(res);
      })
      .then(() => {
        setItLoaded(true);
      })
      .catch(() => {
        toast({ title: "Tasks could not be loaded", status: "error" });
      });

  useEffect(() => {
    handleGetAllSources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const status = {
    STOP: "🟥",
    RUN: "🟩",
    BLOCKED: "⛔",
  };

  return (
    <Flex
      zIndex={105}
      w={"100%"}
      minH={"100vh"}
      maxH={"100vh"}
      p={"60px 10px 30px 0"}
      justifyContent={"center"}
      alignItems={"flex-start"}
    >
      <VStack
        overflowY={"auto"}
        p={"30px"}
        bg={bg}
        spacing={"0"}
        borderRadius={"3xl"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Flex justifyContent={"center"} alignItems={"center"}>
          <TableContainer>
            <Flex
              w={"100%"}
              fontWeight={600}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Heading size="md" pb={2}>
                Tasks
              </Heading>
            </Flex>
            <Table variant="simple" colorScheme="default" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>name</Th>
                  <Th>status</Th>
                  <Th>modified at</Th>
                </Tr>
              </Thead>
              {isLoaded && (
                <Tbody>
                  {tasks.map((task, idx) => (
                    <Tr key={task.id}>
                      <Td p={"1"}>{idx + 1}</Td>
                      <Td p={"1"} value={task.name}>
                        <Text
                          style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                        >
                          {task.name}
                        </Text>
                      </Td>
                      <Td p={"1"} value={task.status} textAlign={"center"}>
                        <Text>{status[task.status]}</Text>
                      </Td>
                      <Td p={"1"} value={task.modified_at}>
                        <Popover trigger="hover" placement="top-start">
                          <PopoverTrigger>
                            <Text overflow={"hidden"} textOverflow={"ellipsis"}>
                              {task.modified_at}
                            </Text>
                          </PopoverTrigger>
                          <PopoverContent w={"100%"}>
                            <PopoverArrow />
                            <PopoverBody>
                              <Text>{task.modified_at}</Text>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
        {!isLoaded && (
          <Flex
            w={"100%"}
            m={"50px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Spinner thickness="3px" size="lg" />
          </Flex>
        )}
      </VStack>
    </Flex>
  );
};

export default observer(Tasks);
