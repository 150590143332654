import React, { useState, useEffect } from "react";
import {
  Flex,
  useColorModeValue,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  useToast,
  Popover,
  PopoverTrigger,
  Text,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Image,
  Link,
  Spinner,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import API from "../api";

const AllSources = () => {
  const toast = useToast({
    position: "bottom-right",
    duration: 3000,
    isClosable: true,
  });
  const bg = useColorModeValue("gray.200", "gray.900");
  const [sources, setSources] = useState([]);
  const [isLoaded, setItLoaded] = useState(false);
  const [showCollapsible, setShowCollapsible] = useState({});

  const toggleCollapsable = (id) => () => {
    setShowCollapsible((set) => ({
      ...set,
      [id]: !set[id],
    }));
  };

  const convertNumber = (number) => {
    if (number < 10) {
      number = "0" + number;
    } else {
      number = "" + number;
    }
    return number;
  };
  const returnDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = convertNumber(date.getDate());
    const month = convertNumber(date.getMonth() + 1);
    const year = date.getFullYear();
    const hours = convertNumber(date.getHours());
    const minutes = convertNumber(date.getMinutes());
    const seconds = convertNumber(date.getSeconds());
    const newDate = `${day}:${month}:${year}`;
    const newTime = `${seconds}:${minutes}:${hours}`;
    return `${newDate} ${newTime}`;
  };

  const handleGetAllSources = () =>
    API.sources
      .getAll()
      .then((res) => {
        setSources(res);
      })
      .then(() => {
        setItLoaded(true);
      })
      .catch(() => {
        toast({ title: "Sources could not be loaded", status: "error" });
      });

  useEffect(() => {
    handleGetAllSources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const status = {
    PARSED: "📑",
    SENT: "✅",
    BLOCKED: "⛔",
  };

  return (
    <Flex
      zIndex={105}
      w={"100%"}
      minH={"100vh"}
      maxH={"100vh"}
      p={"60px 10px 30px 0"}
      justifyContent={"center"}
      alignItems={"flex-start"}
    >
      <VStack
        overflowY={"auto"}
        p={"30px"}
        bg={bg}
        spacing={"0"}
        borderRadius={"3xl"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Flex justifyContent={"center"} alignItems={"center"}>
          <TableContainer>
            <Flex
              w={"100%"}
              fontWeight={600}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Heading size="md" pb={2}>
                All Sources
              </Heading>
            </Flex>
            <Table variant="simple" colorScheme="default" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>title</Th>
                  <Th>text</Th>
                  <Th>status</Th>
                  <Th>assets</Th>
                  <Th>url</Th>
                  <Th>source</Th>
                  <Th>created at</Th>
                  <Th>modified at</Th>
                </Tr>
              </Thead>
              {isLoaded && (
                <Tbody>
                  {sources.map((source, idx) => (
                    <Tr key={idx}>
                      <Td p={"1"}>{idx + 1}</Td>
                      <Td p={"1"} maxW={"150px"} value={source.title}>
                        <Text
                          style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                        >
                          {source.title}
                        </Text>
                      </Td>
                      <Td p={"1"} value={source.text}>
                        {!showCollapsible[source.id] && (
                          <Text
                            maxW="250px"
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            cursor={"pointer"}
                            onClick={toggleCollapsable(source.id)}
                          >
                            {source.text}
                          </Text>
                        )}
                        {showCollapsible[source.id] && (
                          <Text
                            maxW="250px"
                            style={{
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                            cursor={"pointer"}
                            onClick={toggleCollapsable(source.id)}
                          >
                            {source.text}
                          </Text>
                        )}
                      </Td>
                      <Td
                        maxW="160px"
                        p={"1"}
                        value={source.status}
                        textAlign={"center"}
                      >
                        <Text>{status[source.status]}</Text>
                      </Td>
                      <Td maxW="190px" p={"1"} value={source.assets}>
                        <Link href={source.assets} isExternal>
                          <Image
                            size="200px"
                            objectFit="cover"
                            src={source.assets}
                            alt="image"
                          />
                        </Link>
                      </Td>
                      <Td
                        maxW="150px"
                        p={"1"}
                        value={source.url}
                        textAlign={"center"}
                      >
                        <Popover trigger="hover" placement="top-start">
                          <PopoverTrigger>
                            <Link href={source.url} isExternal>
                              <Text
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                              >
                                link
                              </Text>
                            </Link>
                          </PopoverTrigger>
                          <PopoverContent w={"100%"}>
                            <PopoverArrow />
                            <PopoverBody>
                              <Text>{source.url}</Text>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Td>
                      <Td maxW="130px" p={"1"} value={source.source}>
                        <Link href={source.source} isExternal>
                          <Text overflow={"hidden"} textOverflow={"ellipsis"}>
                            {source.source}
                          </Text>
                        </Link>
                      </Td>
                      <Td
                        maxW="100px"
                        p={"1"}
                        textAlign={"center"}
                        value={source.created_at}
                      >
                        <Popover trigger="hover" placement="top-start">
                          <PopoverTrigger>
                            <Text>
                              {returnDate(source.created_at).slice(0, 10)}
                            </Text>
                          </PopoverTrigger>
                          <PopoverContent w={"100%"}>
                            <PopoverArrow />
                            <PopoverBody>
                              <Text>{returnDate(source.created_at)}</Text>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Td>
                      <Td
                        maxW="100px"
                        p={"1"}
                        textAlign={"center"}
                        value={source.modified_at}
                      >
                        <Popover trigger="hover" placement="top-start">
                          <PopoverTrigger>
                            <Text>
                              {returnDate(source.modified_at).slice(0, 10)}
                            </Text>
                          </PopoverTrigger>
                          <PopoverContent w={"100%"}>
                            <PopoverArrow />
                            <PopoverBody>
                              <Text>{returnDate(source.modified_at)}</Text>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
        {!isLoaded && (
          <Flex
            w={"100%"}
            m={"50px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Spinner thickness="3px" size="lg" />
          </Flex>
        )}
      </VStack>
    </Flex>
  );
};

export default observer(AllSources);
