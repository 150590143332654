import React, { useEffect } from "react";
import { Button, HStack, Icon, Text, Tooltip } from "@chakra-ui/react";
import { BiLogOut } from "react-icons/bi";
import { observer } from "mobx-react-lite";
import ColorModeSwitcher from "../../ColorModeSwitcher";

import TOKEN from "../../utils";
import app from "../../store/app";
import API from "../../api";

const UserBar = ({ setIsAuth }) => {
  const handleLogout = () => {
    setIsAuth(false);
    localStorage.removeItem(TOKEN);
  };

  useEffect(() => {
    if (!app.userName) {
      API.authenticate.setName();
    }
  }, []);

  return (
    <HStack
      alignItems={"center"}
      zIndex={"108"}
      spacing={"10px"}
      position="absolute"
      right="15px"
      top="15px"
    >
      <Tooltip label={app.userName}>
        <Text maxWidth={"250px"} noOfLines={1}>
          <b>User</b>: {app.userName}
        </Text>
      </Tooltip>

      <ColorModeSwitcher />
      <Button
        onClick={handleLogout}
        size={"md"}
        variant="ghost"
        pt={"2px"}
        colorScheme={"red"}
      >
        <Icon w={"25px"} h={"25px"} as={BiLogOut} />
      </Button>
    </HStack>
  );
};

export default observer(UserBar);
