import React from "react";
import {
  Button,
  Flex,
  Stack,
  Text,
  useColorModeValue,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ColorModeSwitcher from "../ColorModeSwitcher";
import API from "../api";
import TOKEN from "../utils";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextField from "../components/TextField/TextField";

const Login = ({ setIsAuth }) => {
  const navigate = useNavigate();
  const toast = useToast({
    position: "bottom-right",
    duration: 3000,
    isClosable: true,
  });
  const bg = useColorModeValue("gray.100", "gray.900");
  const bgButton = useColorModeValue("gray.200", "gray.700");
  const [isSmallerThan400] = useMediaQuery("(max-width: 400px)");

  const handleSubmit = (values, actions) => {
      const formData = new FormData();
      formData.append("username", values.username);
      formData.append( "password", values.password);
      API.authenticate
          .auth(formData).then((res) => {
          const token = JSON.stringify(res.access_token);
          localStorage.setItem(TOKEN, token);
      })
          .then(() => setIsAuth(true))
          .then(() => {
              API.authenticate.setName().catch(() => {
                  setIsAuth(false);
                  localStorage.removeItem(TOKEN);
                  navigate("/login");
                  toast({
                      title: "Can't get user information",
                      status: "error",
                  });
              });
          })
          .catch(() => {
              toast({ title: "Invalid password", status: "error" });
          });

      actions.resetForm();}

  return (
    <Formik
        initialValues={{username: "", password: ""}}
        validationSchema={Yup.object({
      username: Yup.string().required("Fill in your username"),
      password: Yup.string().required("Fill in your password"),
    })}
        onSubmit={handleSubmit}
>
            <Flex
                as={Form}
                minH={"100vh"}
                w={"100vw"}
                maxH={"100vh"}
                boxSizing={"content-box"}
                justifyContent={"center"}
                alignItems={"center"}
            >
              <Stack
                  boxSizing={"content-box"}
                  margin={"0 auto"}
                  px={isSmallerThan400 ? "10px" : "50px"}
                  py={"50px"}
                  minW={"220px"}
                  width={"100%"}
                  maxH={"100%"}
                  maxWidth="400px"
                  bg={bg}
              >
                <Text fontSize={"24px"} fontWeight={"600"} textAlign={"center"}>
                  Admin Panel
                </Text>
                <Flex justifyContent={"flex-end"}>
                  <ColorModeSwitcher height={"40px"} width={"30px"}/>
                </Flex>
                <Text
                    fontSize={"22px"}
                    fontWeight={"400"}
                    py={"5px"}
                    textAlign={"center"}
                >
                  {"Authorization"}
                </Text>
                  <TextField name="username" type="text" id="username" placeholder="Fill in your username" autoComplete="username"/>
                <TextField id="password"
                           name="password"
                           placeholder="Fill in your password"
                           autoComplete="current-password"/>
                <Flex pt={"15px"} justifyContent={"center"} width="100%">
                  <Button bg={bgButton} type="submit" width="45%" variant="solid">
                    Login
                  </Button>
                </Flex>
              </Stack>
            </Flex>
    </Formik>
  );
};

export default Login;
