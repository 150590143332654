import axios from "axios";

import { isString } from "lodash-es";
import app from "../store/app";

import TOKEN from "../utils";

const getItemFromLocalStorage = (name) => {
  const storageString = localStorage.getItem(name);
  try {
    return JSON.parse(storageString);
  } catch (e) {
    return "";
  }
};

const getAPIClient = () => {
  const API_BASE_URL = process.env.REACT_APP_HOST;

  const config = {
    baseURL: `${API_BASE_URL}/api/v1`,
  };

  const token = getItemFromLocalStorage(TOKEN);

  if (isString(token) && token !== "") {
    config.headers = { Authorization: `Bearer ${token}` };
  }

  const axiosInstance = axios.create(config);

  axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.href = "/admin/login";
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};

const API = {
  authenticate: {
    auth: async (formData) => {
      const { data } = await getAPIClient().post("/admin/login", formData);

      return data;
    },
    setName: async () => {
      const { data } = await getAPIClient().get("/admin/me");
      app.setUserName(data.user_name);
    },
  },
  sources: {
    getAll: async () => {
      const { data } = await getAPIClient().get(`/news`);
      return data;
    },
  },
  tasks: {
    getAll: async () => {
      const { data } = await getAPIClient().get(`/tasks`);
      return data;
    },
  },
};

export default API;
